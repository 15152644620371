@font-face {
  font-family: "Neue Helvetica";
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaBQ-Bold.svg#NeueHelveticaBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neue Helvetica Condensed";
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.svg#NeueHelveticaCondensedBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Super Nought";
  src: url("../../../fonts/SuperNought.eot");
  src: url("../../../fonts/SuperNought.eot?#iefix") format("embedded-opentype"), url("../../../fonts/SuperNought.woff2") format("woff2"), url("../../../fonts/SuperNought.woff") format("woff"), url("../../../fonts/SuperNought.ttf") format("truetype"), url("../../../fonts/SuperNought.svg#SuperNought") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neucha";
  src: url("../../../fonts/Neucha.eot");
  src: url("../../../fonts/Neucha.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Neucha.woff2") format("woff2"), url("../../../fonts/Neucha.woff") format("woff"), url("../../../fonts/Neucha.ttf") format("truetype"), url("../../../fonts/Neucha.svg#Neucha") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.App-login--main {
  font-family: "Neue Helvetica Condensed", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 30px));
  min-width: 40%;
  max-width: 80%;
  min-height: 50%;
  border-radius: 30px;
  border: 1px solid #000000;
  background: transparent;
  overflow-y: scroll;
  padding: 30px 50px !important;
}
.App-login--main_title {
  font-family: "Super Nought", sans-serif;
  color: #000000;
  font-size: 30px;
}
.App-login--main_text {
  color: #000000;
  font-size: 16px;
  font-weight: normal;
}
.App-login--main_text a {
  color: inherit;
  text-decoration: underline;
}