@font-face {
  font-family: "Neue Helvetica";
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaBQ-Bold.svg#NeueHelveticaBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neue Helvetica Condensed";
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.svg#NeueHelveticaCondensedBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Super Nought";
  src: url("../../../fonts/SuperNought.eot");
  src: url("../../../fonts/SuperNought.eot?#iefix") format("embedded-opentype"), url("../../../fonts/SuperNought.woff2") format("woff2"), url("../../../fonts/SuperNought.woff") format("woff"), url("../../../fonts/SuperNought.ttf") format("truetype"), url("../../../fonts/SuperNought.svg#SuperNought") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neucha";
  src: url("../../../fonts/Neucha.eot");
  src: url("../../../fonts/Neucha.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Neucha.woff2") format("woff2"), url("../../../fonts/Neucha.woff") format("woff"), url("../../../fonts/Neucha.ttf") format("truetype"), url("../../../fonts/Neucha.svg#Neucha") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  overflow: hidden;
}

.is-purple {
  color: #6441A5;
}

.decks_list {
  height: calc(100vh - 70px);
}
.decks_list__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 30px));
  min-width: 70%;
  max-width: 80%;
  max-width: 1260px;
  height: 90%;
  margin: 0 auto;
  border-radius: 30px;
  background-color: #ededed;
  border: 1px solid #000000;
}
.decks_list__content--filters {
  position: relative;
}
.decks_list__content--filters--sort {
  gap: 1.5rem;
}
.decks_list__content--filters::after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background-color: #000000;
}
.decks_list__content--list {
  width: 100%;
  height: 85%;
  padding: 20px 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
}