@font-face {
  font-family: "Neue Helvetica";
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaBQ-Bold.svg#NeueHelveticaBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neue Helvetica Condensed";
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.svg#NeueHelveticaCondensedBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Super Nought";
  src: url("../../../fonts/SuperNought.eot");
  src: url("../../../fonts/SuperNought.eot?#iefix") format("embedded-opentype"), url("../../../fonts/SuperNought.woff2") format("woff2"), url("../../../fonts/SuperNought.woff") format("woff"), url("../../../fonts/SuperNought.ttf") format("truetype"), url("../../../fonts/SuperNought.svg#SuperNought") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neucha";
  src: url("../../../fonts/Neucha.eot");
  src: url("../../../fonts/Neucha.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Neucha.woff2") format("woff2"), url("../../../fonts/Neucha.woff") format("woff"), url("../../../fonts/Neucha.ttf") format("truetype"), url("../../../fonts/Neucha.svg#Neucha") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  overflow: hidden;
}

.is-purple {
  color: #6441A5;
}

.deck_card {
  width: 100%;
  max-width: 250px;
  height: 100%;
  background-color: #000000;
  border-radius: 15px 15px 30px 30px;
  border: 1px solid #fddc5c;
}
.deck_card__code {
  position: relative;
  width: calc(100% + 2px);
  height: 50px;
  border-radius: 15px 15px 0 0;
  background-color: #fddc5c;
  transform: translate(0, -2px);
  cursor: pointer;
}
.deck_card__code span {
  display: block;
  padding: 10px 0;
  text-align: center;
  font-size: 1.2rem;
  color: #000000;
  font-family: "Neue Helvetica Condensed", sans-serif;
  transition: letter-spacing 0.3s ease-in-out;
}
.deck_card__code svg {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #000000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.deck_card__code:hover span {
  letter-spacing: 1px;
}
.deck_card__code:hover svg {
  opacity: 1;
}
.deck_card_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: -2px;
  height: calc(100% - 50px);
}
.deck_card_body__name {
  margin: 25px 0 10px 0;
  padding: 0 10px;
  width: 200px;
  min-height: 70px;
  transition: transform 0.3s ease-in-out;
}
.deck_card_body__name p {
  color: #ffffff;
  font-size: 2rem;
  font-family: "Neue Helvetica Condensed", sans-serif;
  text-align: center;
  line-height: 35px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}
.deck_card_body__description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px 15px;
}
.deck_card_body__description__cards, .deck_card_body__description__type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.deck_card_body__description__cards span, .deck_card_body__description__type span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  font-size: 1.2rem;
  font-family: "Neue Helvetica Condensed", sans-serif;
}
.deck_card_body__description__cards--black {
  background-color: #000000;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.deck_card_body__description__cards--white {
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}
.deck_card_body__description__type span {
  position: relative;
  background-color: #000000;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.deck_card_body__description__type span.adults::after {
  content: "+18";
}
.deck_card_body__description__type span.original::after {
  content: "OG";
}
.deck_card_body__description__type span.twitch::after, .deck_card_body__description__type span.friendly::after {
  content: "";
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}
.deck_card_body__description__type span.twitch::after {
  background-image: url("../../../assets/twitch.svg");
}
.deck_card_body__description__type span.friendly::after {
  background-image: url("../../../assets/friendly.svg");
}
.deck_card_body:hover .deck_card_body__name {
  transform: translateY(-5px);
}

.deck_card_add {
  position: relative;
  cursor: pointer;
}
.deck_card_add .deck_card__name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.deck_card_add .deck_card__name p {
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;
}
.deck_card_add .deck_card__code:hover span {
  letter-spacing: normal;
}
.deck_card_add:hover .deck_card__name p {
  opacity: 1;
}