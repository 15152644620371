.App-menu-logo {
  position: relative;
}
.App-menu-logo__link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
}