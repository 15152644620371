@font-face {
  font-family: "Neue Helvetica";
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaBQ-Bold.svg#NeueHelveticaBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neue Helvetica Condensed";
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.svg#NeueHelveticaCondensedBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Super Nought";
  src: url("../../../fonts/SuperNought.eot");
  src: url("../../../fonts/SuperNought.eot?#iefix") format("embedded-opentype"), url("../../../fonts/SuperNought.woff2") format("woff2"), url("../../../fonts/SuperNought.woff") format("woff"), url("../../../fonts/SuperNought.ttf") format("truetype"), url("../../../fonts/SuperNought.svg#SuperNought") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neucha";
  src: url("../../../fonts/Neucha.eot");
  src: url("../../../fonts/Neucha.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Neucha.woff2") format("woff2"), url("../../../fonts/Neucha.woff") format("woff"), url("../../../fonts/Neucha.ttf") format("truetype"), url("../../../fonts/Neucha.svg#Neucha") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  overflow: hidden;
}

.is-purple {
  color: #6441A5;
}

.App-home-card {
  position: absolute;
  width: 100%;
  max-width: 270px;
  height: 350px;
  background-color: #000000;
  border-radius: 30px;
  border: 5px solid #ffffff;
}
.App-home-card .letter {
  position: absolute;
  left: 20px;
  top: 10px;
  font-size: 2rem;
  color: #ffffff;
  font-family: "Neucha", sans-serif;
}
.App-home-card .mark {
  position: absolute;
  left: 5px;
  top: 45px;
  width: 43px;
  height: 40px;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
}
.App-home-card .mark.club {
  background-image: url("../../../assets/club.svg");
}
.App-home-card .mark.diamond {
  background-image: url("../../../assets/diamond.svg");
}
.App-home-card .mark.heart {
  background-image: url("../../../assets/heart.svg");
}
.App-home-card .mark.spade {
  background-image: url("../../../assets/spade.svg");
}
.App-home-card .text {
  font-size: 2rem;
  color: #ffffff;
  font-family: "Neue Helvetica", sans-serif;
  text-transform: uppercase;
  text-align: center;
  margin-top: 85px;
  line-height: normal;
  padding: 0 15px;
}
.App-home-card.first {
  left: 5%;
  transform: rotate(-23deg);
}
.App-home-card.first .mark {
  transform: rotate(23deg);
}
.App-home-card.second {
  left: 20%;
  transform: rotate(-13deg);
}
.App-home-card.second::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -80px;
  width: calc(100% + 4px);
  height: 220px;
  background-color: #000000;
  border-radius: 30px;
  border: 5px solid #ffffff;
  background-image: url("../../../assets/man1.svg");
  background-position: 100% 20%;
}
@media (max-width: 1366px) {
  .App-home-card.second::after {
    height: 180px;
  }
}
.App-home-card.third {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  max-width: 420px;
  height: 390px;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.App-home-card.third .text {
  font-size: 1.1rem;
  text-align: justify;
  text-transform: none;
  margin: 0 0 25px 0;
  padding: 0 20px;
}
@media (max-width: 1366px) {
  .App-home-card.third .text {
    font-size: 1rem;
  }
}
.App-home-card.third::after, .App-home-card.third::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 50%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.App-home-card.third::after {
  top: 25px;
  background-size: cover;
  background-image: url("../../../assets/men1.svg");
}
@media (max-width: 1366px) {
  .App-home-card.third::after {
    top: 0px;
    height: 60%;
    transform: translateX(-50%) scale(0.9);
  }
}
.App-home-card.third::before {
  top: -50%;
  background-size: contain;
  background-image: url("../../../assets/man3.svg");
}
@media (max-width: 1366px) {
  .App-home-card.third::before {
    top: -45%;
    transform: translateX(-50%) scale(0.8);
  }
}
@media (max-width: 1366px) {
  .App-home-card.third {
    height: 340px;
    bottom: 15%;
  }
}
.App-home-card.fourth {
  right: 20%;
  transform: rotate(10deg);
  z-index: 1;
}
.App-home-card.fourth::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -80px;
  width: calc(100% + 4px);
  height: 220px;
  background-color: #000000;
  border-radius: 30px;
  border: 5px solid #ffffff;
  background-image: url("../../../assets/men2.svg");
  background-position: 80% 30%;
  background-size: auto;
  background-repeat: no-repeat;
}
@media (max-width: 1366px) {
  .App-home-card.fourth::after {
    height: 180px;
  }
}
@media (max-width: 1366px) {
  .App-home-card.fourth::after {
    background-size: 90%;
    background-position: 150% 10%;
  }
}
.App-home-card.fifth {
  right: 5%;
  transform: rotate(23deg);
}
.App-home-card.sixth {
  left: 40%;
  transform: translateX(-40%) rotate(-11deg);
  z-index: -1;
}
.App-home-card.sixth::after {
  content: "";
  position: absolute;
  top: -35px;
  left: 45%;
  transform: translateX(-50%) rotate(10deg);
  background-image: url("../../../assets/man2.svg");
  width: 100%;
  height: 100%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top;
}
@media (max-width: 1366px) {
  .App-home-card.sixth::after {
    top: -60px;
    transform: translateX(-50%) rotate(10deg) scale(0.8);
  }
}
.App-home-card.seventh {
  right: 40%;
  transform: translateX(40%) rotate(11deg);
  z-index: -1;
}
.App-home-card.seventh::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%) rotate(350deg);
  background-image: url("../../../assets/man4.svg");
  width: 100%;
  height: 100%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top;
}
@media (max-width: 1366px) {
  .App-home-card.seventh::after {
    top: -40px;
    transform: translateX(-50%) rotate(350deg) scale(0.8);
  }
}
.App-home-card.sixth, .App-home-card.seventh {
  top: 60px;
}
@media (max-width: 1366px) {
  .App-home-card.sixth, .App-home-card.seventh {
    top: 90px;
  }
}
@media (max-width: 1366px) {
  .App-home-card {
    height: 300px;
  }
  .App-home-card .text {
    font-size: 1.8rem;
  }
}