@font-face {
  font-family: "Neue Helvetica";
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaBQ-Bold.svg#NeueHelveticaBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neue Helvetica Condensed";
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.svg#NeueHelveticaCondensedBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Super Nought";
  src: url("../../../fonts/SuperNought.eot");
  src: url("../../../fonts/SuperNought.eot?#iefix") format("embedded-opentype"), url("../../../fonts/SuperNought.woff2") format("woff2"), url("../../../fonts/SuperNought.woff") format("woff"), url("../../../fonts/SuperNought.ttf") format("truetype"), url("../../../fonts/SuperNought.svg#SuperNought") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neucha";
  src: url("../../../fonts/Neucha.eot");
  src: url("../../../fonts/Neucha.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Neucha.woff2") format("woff2"), url("../../../fonts/Neucha.woff") format("woff"), url("../../../fonts/Neucha.ttf") format("truetype"), url("../../../fonts/Neucha.svg#Neucha") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  overflow: hidden;
}

.is-purple {
  color: #6441A5;
}

.details_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 30px));
  min-width: 70%;
  max-width: 80%;
  height: 80%;
  border-radius: 30px;
  border: 1px solid #000;
  background: #ededed;
}
.details_container__top {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-family: "Neue Helvetica Condensed", sans-serif;
  color: #000000;
}
.details_container__top--lang {
  flex-grow: 0;
  border-radius: 30px 0 0 0;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
  text-transform: uppercase;
  min-width: 250px;
}
.details_container__top--type {
  flex-grow: 1;
  text-transform: uppercase;
  border-bottom: 1px solid #000000;
}
.details_container__top--code {
  flex-grow: 0;
  border-radius: 0 30px 0 0;
  background-color: #fddc5c;
  border-left: 1px solid #000000;
  border-bottom: 1px solid #000000;
  min-width: 250px;
}
.details_container__middle {
  width: 100%;
  border-bottom: 1px solid #000000;
  font-family: "Neue Helvetica Condensed", sans-serif;
  color: #000000;
  padding: 5px 0 10px 0;
}
.details_container__middle--name {
  font-size: 2rem;
  text-transform: uppercase;
}
.details_container__middle--author {
  font-size: 0.8rem;
  margin-top: -10px;
  text-transform: uppercase;
}
.details_container__bottom {
  position: relative;
  width: 100%;
  height: calc(100% - 120px);
  text-align: center;
}
.details_container__bottom--white, .details_container__bottom--black {
  width: 100%;
  height: 100%;
}
.details_container__bottom--white--title, .details_container__bottom--black--title {
  padding: 25px;
  text-transform: uppercase;
  font-size: 2rem;
  font-family: "Super Nought", sans-serif;
  border-bottom: 1px solid #000000;
}
.details_container__bottom--white--cards, .details_container__bottom--black--cards {
  margin: 30px 10px 0 0;
  gap: 10px;
  max-height: calc(100% - 150px);
  overflow: auto;
}
.details_container__bottom--white--cards::-webkit-scrollbar, .details_container__bottom--black--cards::-webkit-scrollbar {
  width: 10px;
}
.details_container__bottom--white--cards::-webkit-scrollbar-track, .details_container__bottom--black--cards::-webkit-scrollbar-track {
  background-color: transparent;
}
.details_container__bottom--white--cards::-webkit-scrollbar-thumb, .details_container__bottom--black--cards::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 10px;
}
.details_container__bottom--white--cards span, .details_container__bottom--black--cards span {
  display: block;
  width: 80%;
  background-color: #D9D9D9;
  border-radius: 25px;
  font-size: 1.2rem;
  color: #000000;
  font-family: "Neue Helvetica Condensed", sans-serif;
  padding: 10px;
}
.details_container__bottom--white--title {
  color: #000000;
  background-color: #ffffff;
}
.details_container__bottom--black--title {
  color: #ffffff;
  background-color: #000000;
}
.details_container__bottom::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #000000;
  left: 50%;
  transform: translateX(-50%);
}