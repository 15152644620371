@font-face {
  font-family: "Neue Helvetica";
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaBQ-Bold.svg#NeueHelveticaBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neue Helvetica Condensed";
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.svg#NeueHelveticaCondensedBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Super Nought";
  src: url("../../../fonts/SuperNought.eot");
  src: url("../../../fonts/SuperNought.eot?#iefix") format("embedded-opentype"), url("../../../fonts/SuperNought.woff2") format("woff2"), url("../../../fonts/SuperNought.woff") format("woff"), url("../../../fonts/SuperNought.ttf") format("truetype"), url("../../../fonts/SuperNought.svg#SuperNought") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neucha";
  src: url("../../../fonts/Neucha.eot");
  src: url("../../../fonts/Neucha.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Neucha.woff2") format("woff2"), url("../../../fonts/Neucha.woff") format("woff"), url("../../../fonts/Neucha.ttf") format("truetype"), url("../../../fonts/Neucha.svg#Neucha") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  overflow: hidden;
}

.is-purple {
  color: #6441A5;
}

.basic_select.sort {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.basic_select.sort label {
  position: relative;
  font-size: 1.2rem;
  font-family: "Neue Helvetica Condensed", sans-serif;
  color: #000000;
}
.basic_select.sort label::after {
  content: "|";
  position: absolute;
  top: -2px;
  right: -8px;
}
.basic_select.sort select {
  background: none;
  border: none;
  font-size: 1.2rem;
  font-family: "Neue Helvetica Condensed", sans-serif;
  color: #000000;
  text-align: center;
  cursor: pointer;
}
.basic_select.sort select:focus {
  outline-color: #c9c9c9;
}
.basic_select.sort select option {
  background: #000;
  color: #fff;
}