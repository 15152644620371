@font-face {
  font-family: "Neue Helvetica";
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaBQ-Bold.svg#NeueHelveticaBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neue Helvetica Condensed";
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.svg#NeueHelveticaCondensedBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Super Nought";
  src: url("../../../fonts/SuperNought.eot");
  src: url("../../../fonts/SuperNought.eot?#iefix") format("embedded-opentype"), url("../../../fonts/SuperNought.woff2") format("woff2"), url("../../../fonts/SuperNought.woff") format("woff"), url("../../../fonts/SuperNought.ttf") format("truetype"), url("../../../fonts/SuperNought.svg#SuperNought") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neucha";
  src: url("../../../fonts/Neucha.eot");
  src: url("../../../fonts/Neucha.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Neucha.woff2") format("woff2"), url("../../../fonts/Neucha.woff") format("woff"), url("../../../fonts/Neucha.ttf") format("truetype"), url("../../../fonts/Neucha.svg#Neucha") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  overflow: hidden;
}

.is-purple {
  color: #6441A5;
}

.deck_creator {
  width: 100%;
  max-width: 1200px;
  margin: 15px auto;
}
.deck_creator__title {
  font-family: "Super Nought", sans-serif;
  font-size: 3rem;
  color: #000000;
  text-align: center;
  text-transform: uppercase;
}
.deck_creator__steps {
  width: 100%;
  max-width: 700px;
  margin-top: 10px;
}
.deck_creator__steps--1, .deck_creator__steps--2, .deck_creator__steps--3 {
  max-width: 70px;
}
.deck_creator__steps--1 button, .deck_creator__steps--2 button, .deck_creator__steps--3 button {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #000000;
  font-family: "Neue Helvetica Condensed", sans-serif;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  z-index: 1;
}
.deck_creator__steps--1 span, .deck_creator__steps--2 span, .deck_creator__steps--3 span {
  font-family: "Neue Helvetica Condensed", sans-serif;
  font-size: 1rem;
  color: #000000;
  text-transform: uppercase;
  margin-top: 5px;
  white-space: nowrap;
}
.deck_creator__steps--2 {
  position: relative;
}
.deck_creator__steps--2::before, .deck_creator__steps--2::after {
  content: "";
  position: absolute;
  width: 330px;
  height: 1px;
  background-color: #000000;
  top: 35%;
  transform: translateY(-50%);
  z-index: 0;
}
.deck_creator__steps--2::before {
  left: -275px;
}
.deck_creator__steps--2::after {
  right: -275px;
}
.deck_creator__content {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 500px;
  overflow: hidden;
}
.deck_creator__content--step-1, .deck_creator__content--step-2, .deck_creator__content--step-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: transform 0.3s ease-in-out;
}
.deck_creator__content--step-2, .deck_creator__content--step-3 {
  transform: translateX(1500px);
}
.deck_creator__content--step-1 {
  flex-direction: column;
  gap: 20px;
}
.deck_creator__content--step-1 label {
  width: 100%;
  display: block;
  font-size: 1.2rem;
  margin-bottom: 5px;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
  font-family: "Neue Helvetica Condensed", sans-serif;
}
.deck_creator__content--step-1 input, .deck_creator__content--step-1 select {
  width: 100%;
  padding: 15px 25px;
  border-radius: 15px;
  border: 1px solid;
  font-family: "Neue Helvetica", sans-serif;
}
.deck_creator__content--step-1--name, .deck_creator__content--step-1--lang, .deck_creator__content--step-1--type, .deck_creator__content--step-1--privacy {
  width: 100%;
  max-width: 450px;
}
.deck_creator__content--step-2 {
  padding-top: 50px;
}
.deck_creator__content--step-2 .white_cards, .deck_creator__content--step-2 .black_cards {
  position: relative;
  width: 100%;
  height: 100%;
}
.deck_creator__content--step-2 .white_cards__counter, .deck_creator__content--step-2 .black_cards__counter {
  position: absolute;
  top: 25px;
  font-family: "Neue Helvetica Condensed", sans-serif;
}
.deck_creator__content--step-2 .white_cards__add, .deck_creator__content--step-2 .black_cards__add {
  height: 30%;
  position: relative;
  margin-top: 15px;
  gap: 15px;
}
.deck_creator__content--step-2 .white_cards__add p, .deck_creator__content--step-2 .black_cards__add p {
  font-family: "Neue Helvetica Condensed", sans-serif;
  font-size: 1.4rem;
}
.deck_creator__content--step-2 .white_cards__add .basic_input, .deck_creator__content--step-2 .white_cards__add .basic_input input, .deck_creator__content--step-2 .white_cards__add button, .deck_creator__content--step-2 .black_cards__add .basic_input, .deck_creator__content--step-2 .black_cards__add .basic_input input, .deck_creator__content--step-2 .black_cards__add button {
  width: 100%;
  max-width: 430px;
  border-radius: 15px;
  text-align: center;
}
.deck_creator__content--step-2 .white_cards__add .basic_input input, .deck_creator__content--step-2 .white_cards__add button, .deck_creator__content--step-2 .black_cards__add .basic_input input, .deck_creator__content--step-2 .black_cards__add button {
  padding: 10px 25px;
}
.deck_creator__content--step-2 .white_cards__add .basic_input input, .deck_creator__content--step-2 .black_cards__add .basic_input input {
  font-family: "Neue Helvetica", sans-serif;
}
.deck_creator__content--step-2 .white_cards__add button, .deck_creator__content--step-2 .black_cards__add button {
  font-family: "Super Nought", sans-serif;
  font-size: 1.5rem;
  cursor: pointer;
  transition: filter 0.3s ease-in-out;
}
.deck_creator__content--step-2 .white_cards__add::after, .deck_creator__content--step-2 .black_cards__add::after {
  content: "";
  position: absolute;
  width: 90%;
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -25px;
}
.deck_creator__content--step-2 .white_cards__list, .deck_creator__content--step-2 .black_cards__list {
  height: 70%;
}
.deck_creator__content--step-2 .white_cards__list > p, .deck_creator__content--step-2 .black_cards__list > p {
  font-family: "Neue Helvetica Condensed", sans-serif;
  font-size: 1.4rem;
}
.deck_creator__content--step-2 .white_cards__list__container, .deck_creator__content--step-2 .black_cards__list__container {
  width: 100%;
  height: 60%;
  overflow: auto;
  max-width: 400px;
  text-align: center;
  gap: 15px;
  margin: 10px 0 0 45px;
  padding-right: 35px;
}
.deck_creator__content--step-2 .white_cards__list__container::-webkit-scrollbar, .deck_creator__content--step-2 .black_cards__list__container::-webkit-scrollbar {
  width: 10px;
}
.deck_creator__content--step-2 .white_cards__list__container::-webkit-scrollbar-track, .deck_creator__content--step-2 .black_cards__list__container::-webkit-scrollbar-track {
  background-color: transparent;
}
.deck_creator__content--step-2 .white_cards__list__container::-webkit-scrollbar-thumb, .deck_creator__content--step-2 .black_cards__list__container::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.deck_creator__content--step-2 .white_cards__list__container__element, .deck_creator__content--step-2 .black_cards__list__container__element {
  position: relative;
}
.deck_creator__content--step-2 .white_cards__list__container__element p, .deck_creator__content--step-2 .black_cards__list__container__element p {
  font-family: "Neue Helvetica Condensed", sans-serif;
  font-size: 1rem;
}
.deck_creator__content--step-2 .white_cards__list__container__element p:last-child, .deck_creator__content--step-2 .black_cards__list__container__element p:last-child {
  border-bottom: none;
}
.deck_creator__content--step-2 .white_cards__list__container__element .delete, .deck_creator__content--step-2 .black_cards__list__container__element .delete {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.1s ease-in-out;
}
.deck_creator__content--step-2 .white_cards {
  background-color: #ffffff;
  color: #000000;
  border-radius: 25px 0 0 25px;
}
.deck_creator__content--step-2 .white_cards__counter {
  left: 30px;
}
.deck_creator__content--step-2 .white_cards__add .basic_input input, .deck_creator__content--step-2 .white_cards__add button {
  border: 1px solid #000000;
}
.deck_creator__content--step-2 .white_cards__add button {
  background-color: #000000;
  color: #ffffff;
}
.deck_creator__content--step-2 .white_cards__add button:hover {
  filter: drop-shadow(0px 0px 3px #000000);
}
.deck_creator__content--step-2 .white_cards__add::after {
  background-color: #000000;
}
.deck_creator__content--step-2 .white_cards__list__container::-webkit-scrollbar-thumb {
  background-color: #000000;
}
.deck_creator__content--step-2 .white_cards__list__container p {
  border-bottom: 1px solid #000000;
}
.deck_creator__content--step-2 .black_cards {
  background-color: #000000;
  color: #ffffff;
  border-radius: 0 25px 25px 0;
}
.deck_creator__content--step-2 .black_cards__counter {
  right: 30px;
}
.deck_creator__content--step-2 .black_cards__add .basic_input input, .deck_creator__content--step-2 .black_cards__add button {
  border: 1px solid #ffffff;
}
.deck_creator__content--step-2 .black_cards__add .basic_input input {
  background-color: #000000;
  color: #ffffff;
}
.deck_creator__content--step-2 .black_cards__add button:hover {
  filter: drop-shadow(0px 0px 3px white);
}
.deck_creator__content--step-2 .black_cards__add::after {
  background-color: #ffffff;
}
.deck_creator__content--step-2 .black_cards__list__container::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}
.deck_creator__content--step-2 .black_cards__list__container p {
  border-bottom: 1px solid #ffffff;
}
.deck_creator__content--step-3 .deck_card {
  max-height: 330px;
}
.deck_creator__content--step-3 .deck_card_body {
  padding-top: 15px;
  cursor: default;
  pointer-events: none;
}
.deck_creator__content--step-3 .error {
  position: absolute;
  bottom: 0;
  color: red;
  font-family: "Neue Helvetica Condensed", sans-serif;
}
.deck_creator__buttons {
  position: absolute;
  bottom: 5%;
  width: 100%;
  max-width: 1200px;
}
.deck_creator__buttons__left, .deck_creator__buttons__right {
  width: 100%;
}
.deck_creator__buttons__left button, .deck_creator__buttons__right button {
  position: relative;
  width: 100%;
  color: #ffffff;
  font-family: "Super Nought", sans-serif;
  background-color: #000000;
  border-radius: 15px;
  padding: 10px 20px;
  border: none;
  text-transform: uppercase;
  transition: transform 0.3s ease-in-out;
  max-width: 350px;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 20px 10px;
}
.deck_creator__buttons__left button:hover, .deck_creator__buttons__right button:hover {
  color: #ffffff;
  transform: translate(0, -5px);
}
.deck_creator.step-1 .deck_creator__steps button, .deck_creator.step-2 .deck_creator__steps button, .deck_creator.step-3 .deck_creator__steps button {
  background-color: #ededed;
  color: #000000;
}
.deck_creator.step-1 .deck_creator__steps--1 button {
  background-color: #000000;
  color: #ffffff;
}
.deck_creator.step-1 .deck_creator__content--step-1 {
  transform: translateX(0);
}
.deck_creator.step-1 .deck_creator__content--step-2 {
  transform: translateX(1500px);
}
.deck_creator.step-1 .deck_creator__content--step-3 {
  transform: translateX(1500px);
}
.deck_creator.step-1 .deck_creator__buttons .prev {
  display: none;
}
.deck_creator.step-1 .deck_creator__buttons .create {
  display: none;
}
.deck_creator.step-2 .deck_creator__steps--2 button {
  background-color: #000000;
  color: #ffffff;
}
.deck_creator.step-2 .deck_creator__content--step-1 {
  transform: translateX(-1500px);
}
.deck_creator.step-2 .deck_creator__content--step-2 {
  transform: translateX(0);
}
.deck_creator.step-2 .deck_creator__content--step-3 {
  transform: translateX(1500px);
}
.deck_creator.step-2 .deck_creator__buttons .prev {
  display: block;
}
.deck_creator.step-2 .deck_creator__buttons .create {
  display: none;
}
.deck_creator.step-3 .deck_creator__steps--3 button {
  background-color: #000000;
  color: #ffffff;
}
.deck_creator.step-3 .deck_creator__content--step-1 {
  transform: translateX(-1500px);
}
.deck_creator.step-3 .deck_creator__content--step-2 {
  transform: translateX(-1500px);
}
.deck_creator.step-3 .deck_creator__content--step-3 {
  transform: translateX(0);
}
.deck_creator.step-3 .deck_creator__buttons .prev {
  display: block;
}
.deck_creator.step-3 .deck_creator__buttons .create {
  display: block;
}
.deck_creator.step-3 .deck_creator__buttons .next {
  display: none;
}
.deck_creator::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #ededed;
  z-index: -1;
  bottom: 0;
}