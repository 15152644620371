@font-face {
  font-family: "Neue Helvetica";
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaBQ-Bold.svg#NeueHelveticaBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neue Helvetica Condensed";
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.svg#NeueHelveticaCondensedBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Super Nought";
  src: url("../../../fonts/SuperNought.eot");
  src: url("../../../fonts/SuperNought.eot?#iefix") format("embedded-opentype"), url("../../../fonts/SuperNought.woff2") format("woff2"), url("../../../fonts/SuperNought.woff") format("woff"), url("../../../fonts/SuperNought.ttf") format("truetype"), url("../../../fonts/SuperNought.svg#SuperNought") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neucha";
  src: url("../../../fonts/Neucha.eot");
  src: url("../../../fonts/Neucha.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Neucha.woff2") format("woff2"), url("../../../fonts/Neucha.woff") format("woff"), url("../../../fonts/Neucha.ttf") format("truetype"), url("../../../fonts/Neucha.svg#Neucha") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.LoginForm--button {
  position: relative;
  width: 100%;
  color: #ffffff;
  font-family: "Super Nought", sans-serif;
  background-color: #000000;
  border-radius: 25px;
  padding: 10px 20px;
  border: none;
  text-transform: uppercase;
  transition: transform 0.15s ease-in-out, letter-spacing 0.15s 0.15s ease-in-out;
  font-size: 1.5rem;
}
.LoginForm--button.google svg {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.LoginForm .field {
  text-align: left;
}
.LoginForm .field label {
  font-size: 16px;
  font-family: "Neue Helvetica", sans-serif;
  font-weight: normal;
}
.LoginForm .field input {
  border-radius: 15px;
  background-color: transparent;
  border: 1px solid #000000;
}
.LoginForm .field input.is-danger {
  border-color: #f14668 !important;
}
.LoginForm .field input::placeholder {
  color: #606060;
  padding-left: 10px;
  font-weight: bold;
}
.LoginForm--other-providers {
  border-bottom: 1px solid black;
  position: relative;
  margin: 50px 0;
}
.LoginForm--other-providers span {
  font-size: 12px;
  font-family: "Neue Helvetica", sans-serif;
  color: #000000;
  position: absolute;
  bottom: -9px;
  background-color: #f5f5f5;
  padding: 0 10px;
  left: 50%;
  transform: translateX(-50%);
}