@font-face {
  font-family: "Neue Helvetica";
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaBQ-Bold.svg#NeueHelveticaBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neue Helvetica Condensed";
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot");
  src: url("../../../fonts/NeueHelveticaCondensedBQ-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff2") format("woff2"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.woff") format("woff"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.ttf") format("truetype"), url("../../../fonts/NeueHelveticaCondensedBQ-Bold.svg#NeueHelveticaCondensedBQ-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Super Nought";
  src: url("../../../fonts/SuperNought.eot");
  src: url("../../../fonts/SuperNought.eot?#iefix") format("embedded-opentype"), url("../../../fonts/SuperNought.woff2") format("woff2"), url("../../../fonts/SuperNought.woff") format("woff"), url("../../../fonts/SuperNought.ttf") format("truetype"), url("../../../fonts/SuperNought.svg#SuperNought") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neucha";
  src: url("../../../fonts/Neucha.eot");
  src: url("../../../fonts/Neucha.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Neucha.woff2") format("woff2"), url("../../../fonts/Neucha.woff") format("woff"), url("../../../fonts/Neucha.ttf") format("truetype"), url("../../../fonts/Neucha.svg#Neucha") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.Creator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Creator--img {
  width: 220px;
  height: 220px;
  object-fit: cover;
  border-radius: 10000px;
  filter: grayscale(100%) contrast(1) brightness(95%);
  transition: filter 0.2s ease-in-out;
}
.Creator--img:hover {
  filter: grayscale(20%) contrast(1) brightness(100%);
}
.Creator--socials a {
  color: inherit;
  text-decoration: inherit;
  transition: color 0.5s ease-in-out;
}
.Creator--socials a:hover {
  color: #000000;
}